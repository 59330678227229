<template>
  <section class="box-height" style="margin-bottom: 70px">
    <div class="box-title" style="border: 1px #e5e5e5 solid">
      <el-row class="searchBox">
        <el-button
          type="default"
          plain
          size="mini"
          class="mgl10"
          @click="addNew"
          v-has="'setting:defaultkpi:add'"
        >
          <i class="el-icon-plus"></i> 增加业绩分配者
        </el-button>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24" class="defaultKpi">
          <el-table
            class="tabBorder custor"
            :header-row-style="{ height: '36px' }"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            :data="ListData"
            v-loading="listLoading"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              prop="roleName"
              label="分配角色名称"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="module"
              label="默认比例"
            >
              <template slot-scope="scope"
                >{{ scope.row.percent }}%
                {{
                  scope.row.expireRule ? "(" + scope.row.expireRule + ")" : ""
                }}</template
              >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop
              label="操作"
              width="200"
            >
              <template slot-scope="scope">
                <span
                  @click="handleEdit(scope.row.id)"
                  v-has="'setting:defaultkpi:edit'"
                  class="tabHref"
                  >编辑</span
                >
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>

    <el-dialog
      :title="titName"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        @submit.native.prevent
        :model="createDate"
        ref="createDate"
        :rules="rules"
        label-width="140px"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="分配角色名称：" prop="roleName">
              <el-input
                v-model.trim="createDate.roleName"
                :readonly="readonly"
                style="width: 320px"
                maxlength="20"
                placeholder="请不要输入超过20个字"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="默认比例：" prop="percent">
              <el-input
                v-model.number="createDate.percent"
                style="width: 320px"
              >
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          @click="
            dialogVisible = false;
            resetForm('createDate');
          "
          >取 消</el-button
        >
        <el-button
          size="mini"
          type="primary"
          @click="save('createDate')"
          :loading="saveloading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>
<script>
import {
  getdefaultKPIList,
  addOrEditdefaultKPI,
  deletedefaultKPI,
  getdefaultKPIById,
} from "../../api/api";
export default {
  data() {
    let checkCent = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("比例不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (value > 100 || value < 0) {
            callback(new Error("请输入1-100的数字"));
          } else {
            callback();
          }
        }
      }, 500);
    };
    return {
      ListData: [],
      listLoading: false,
      dialogVisible: false,
      readonly: false,
      saveloading: false,
      rules: {
        roleName: [
          {
            required: true,
            trigger: "blur",
            message: "请填写名称",
          },
        ],
        percent: [
          {
            required: true,
            trigger: "blur",
            validator: checkCent,
          },
        ],
      },
      createDate: {
        id: 0,
        roleName: null,
        percent: null,
        version: 0,
      },
      titName: "",
    };
  },
  methods: {
    checkInt(n, max) {
      var regex = /^\d+$/;
      if (regex.test(n)) {
        if (n < max && n > 0) {
          alert("这是小于" + max + "的正整数！！");
        } else {
          alert("这不是小于" + max + "的正整数！！");
        }
      } else {
        alert("非整数");
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    addNew() {
      this.createDate.id = 0;
      this.createDate.version = 0;
      this.dialogVisible = true;
      this.readonly = false;
      this.titName = "新增分配者信息";
    },
    handleEdit(id) {
      this.dialogVisible = true;
      this.titName = "编辑分配者信息";
      this.readonly = true;
      getdefaultKPIById({
        id: id,
      }).then((res) => {
        if (res.success) {
          this.createDate.roleName = res.result.roleName;
          this.createDate.percent = res.result.percent;
          this.createDate.id = id;
          this.createDate.version = res.result.version;
        }
      });
    },
    handleClose(done) {
      done();
      this.resetForm("createDate");
    },
    handleDel(id) {
      this.$confirm("请确认是否删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deletedefaultKPI({ id: id }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveloading = true;
          addOrEditdefaultKPI(this.createDate).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.dialogVisible = false;
              this.resetForm("createDate");
              this.loadList();
            }
            this.saveloading = false;
          });
        } else {
          return false;
        }
      });
    },
    loadList() {
      this.listLoading = true;
      getdefaultKPIList().then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result;
        } else {
          this.listLoading = false;
        }
      });
    },
  },
  created() {
    this.loadList();
  },
};
</script>

<style lang="scss" scoped>
.box-title {
  padding: 15px 0;
}
.add {
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px dashed #e7e5e4;
  color: #999;
  margin: 40px auto;
  display: block;
  cursor: pointer;
  &:hover {
    border-color: #ff3f3f;
    color: #ff3f3f;
  }
}
</style>

